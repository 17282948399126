var isMobile;
isMobile = $(window).width() < 993;
$(function () {

    $(document).on('click', '.openContactFrom', function (e) {
        e.preventDefault();
        window.scrollTo(0, 0);
        $('.contact-cta a').get(0).click();
    });

    //if is mobile
    if (isMobile) {
        $('body').addClass('is-mobile').css('padding-top', $('.mobile-header').height());
        $(document).on('click', '.hamburguer:not(.show)', function () {
            showMenu(true);
        })
        $(document).on('click', '.hamburguer.show, .overlaySite', function () {
            hideMenu(true);
            hideUserMenu(true);
        })
        $(document).on('click', '.user-nav:not(.show)', function () {
            showUserMenu(true);
        })
        $(document).on('click', '.user-nav.show, .overlaySite', function () {
            hideUserMenu(true);
            hideMenu(true);
        })
    }
    /* Profile page scripts */
    profilePage();

    //Open contact form
    $('.contact-cta a, .contact-cta').on('click', function () {
        $('.contactModal').addClass('show');
        if ($(window).width() < 992) {
            hideMenu(false);
        }
    })
    //close contact form
    $('.contactModal .close-btn').on('click', function () {
        $('.contactModal').removeClass('show');
        if ($(window).width() < 992) {
            $('.overlaySite').fadeOut(400);
        }
    })
    $('form .form-actions button[type=submit]').wrapInner('<div class="inner"></div>');
    $('form .control-label.col-sm-3').removeClass('col-sm-3');
    if ($(window).width() > 992) {
        var minH = $(window).height() - ($('header.header').height() + $('footer').height());
        $('.page-content').css('min-height', minH - 50);
    }

    if ($(window).width() < 992) {
    }

    $('.folder-lvl-0 .folder-lvl-0-title a').click(function(){
        var img = $(this).find('> img')[0];
        var src = img.src;
        if(src.includes('plus-icon-closed')) {
            src = src.replace('plus-icon-closed', 'minus-icon-closed');
        } else {
            src = src.replace('minus-icon-closed', 'plus-icon-closed');
        }
        img.src = src;
    });
    $('.folder-lvl-1 .panel-title a').click(function(){
        var img = $(this).find('> img')[0];
        var src = img.src;
        if(src.includes('plus-icon-closed')) {
            src = src.replace('plus-icon-closed', 'minus-icon-closed');
        } else {
            src = src.replace('minus-icon-closed', 'plus-icon-closed');
        }
        img.src = src;
    });
    $('.folder-lvl-2 .panel-title a').click(function(){
        var img = $(this).find('> img')[0];
        var src = img.src;
        if(src.includes('plus-icon-opened')) {
            src = src.replace('plus-icon-opened', 'minus-icon-opened');
        } else {
            src = src.replace('minus-icon-opened', 'plus-icon-opened');
        }
        img.src = src;
    });




})

function showMenu() {
    $('.left-menu').addClass('show');
    $('.hamburguer').addClass('show');
    $('.overlaySite').fadeIn(400);
}

function hideMenu($hideOverlay) {
    // $hideOverlay = $hideOverlay || false;
    $('.left-menu').removeClass('show');
    $('.hamburguer').removeClass('show');
    if ($hideOverlay) {
        $('.overlaySite').fadeOut(400)
    }
}

function showUserMenu() {
    $('.right-menu').addClass('show');
    $('.overlaySite').fadeIn(400);
}

function hideUserMenu($hideOverlay) {
    // $hideOverlay = $hideOverlay || false;
    $('.right-menu').removeClass('show');
    if ($hideOverlay) {
        $('.overlaySite').fadeOut(400)
    }
}

$(window).on('load', function () {
    if (!isMobile && $('body').attr('data-page') == 'beneficios') {
        $('.panel-collapse').addClass('in');
        $('[data-target]').attr('data-target', '');
    }
})

function profilePage() {
    if ($('body').attr('data-page') == 'profile') {
        $('.collection-item').addClass('disabled');
        $('.collection-item').find('input,textarea,select').prop("readonly", true);
        $('.collection-item').each(function () {
            $(this).find('select').each(function () {
                var _val = $(this).val()
                $('<p class="selectRef">' + _val + '</p>').insertAfter($(this));
                $(this).on('change', function () {
                    $(this).parent().find('.selectRef').text($(this).val())
                })
            })
        })
        // $('.collection-item.disabled input,.collection-item.disabled select,.collection-item.disabled textarea').on('focus', function () {
        //     $(this).blur();
        // })
        $('.collection-item').append('<a class="editCollectionItem"><i class="fa fa-pencil" aria-hidden="true"></i>Editar</a>');


        $('.btn.btn-primary[data-collection-add-btn]').on('click', function () {
            var $this = $(this);
            $('.collection-item').addClass('disabled');
            $('.collection-item .form-group').find('input,textarea,select').attr("readonly", true);
            $('.collection-item').removeClass('editing');
            setTimeout(function () {
                $this.closest('.form-group').find('.collection-items .collection-item:last-child').append('<a href="#"  class="editCollectionItem tip" data-tip=""><i class="fa fa-pencil" aria-hidden="true"></i>Editar</a>');
                $this.closest('.form-group').find('.collection-items .collection-item:last-child').find('.editCollectionItem').click();
            }, 150)
        })

        $(document).on('click', '.editCollectionItem', function (e) {
            e.preventDefault();
            var $this = $(this);
            $this.closest('.collection-item.disabled').removeClass('disabled').addClass('editing');
            $this.closest('.collection-item').find('input,textarea,select').prop("readonly", false);
            $this.closest('.collection-item').find('.form-group:first-child input').focus();
            $this.closest('.collection-item').find('.js-datepicker').each(function () {
                $(this).datetimepicker({
                    format: 'DD/MM/YYYY',
                    locale: 'es'
                });
            })
        })
    }
}
